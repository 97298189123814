const defaults = { spinner: false };

const template = `
  <div class="blitz-inner-wrapper">
    <span class="blitz-spinner blitz-hide"><span></span></span>
    <span class="blitz-body">
      <span class="blitz-message"></span>
    </span>
    <button aria-label="Close notification" class="blitz-close" />
  </div>
`;

class Blitz {
  constructor(selector, options = {}) {
    this.options = Object.assign({}, defaults, options);
    this.container = document.querySelector(selector);
    this.rendered = false;
    this.autoHideTimeout = null;
  }

  alert(message, options = {}) {
    this._display(message, 'alert', options);
  }

  notice(message, options = {}) {
    this._display(message, 'notice', options);
  }

  success(message, options = {}) {
    this._display(message, 'success', options);
  }

  hide() {
    if (this.rendered) {
      this.wrapper.classList.add('blitz-hide');
      this.spinner.classList.add('blitz-hide');
    }
  }

  setOptions(options) {
    for (let [key, value] of options) {
      this.options[key] = value;
    }
  }

  _display(message, kind, options) {
    if (!message.trim()) return;

    options = Object.assign(this.options, options);

    this._render();
    this._toggleSpinner(options.spinner);

    this.message.innerHTML = message;
    this.wrapper.classList.remove('blitz-hide', 'notice', 'alert', 'success');
    this.wrapper.classList.add(kind);
  }

  _render() {
    if (this.rendered) return;

    this.rendered = true;

    this.wrapper = document.createElement('div');
    this.wrapper.classList.add('blitz', 'blitz-hide');
    this.wrapper.innerHTML = template;
    this.wrapper.setAttribute('role', 'alertdialog');
    this.message = this.wrapper.querySelector('.blitz-message');
    this.spinner = this.wrapper.querySelector('.blitz-spinner');
    this.close = this.wrapper.querySelector('.blitz-close');
    this.close.onclick = () => this.hide();

    this.container.appendChild(this.wrapper);

    this.close.focus();
  }

  _toggleSpinner(show) {
    const classList = this.spinner.classList;

    show ? classList.remove('blitz-hide') : classList.add('blitz-hide');
  }
}

window.Blitz = Blitz;
